import React, { useEffect, useState } from "react";

const App = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIsMobile();

    window.addEventListener("resize", checkIsMobile);

    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  return (
    <div>
      <iframe
        src={isMobile ? "https://1buy.io/pay" : "https://1buy.io/invoice"}
        style={{
          border: "none",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
        title="Full Screen 1buy.io"
      ></iframe>
    </div>
  );
};

export default App;
